var Options = [
  {
    label: 'Background Style',
    type: 'avatarStyle',
    attribute: 'avatarStyle',
    values: ['Circle', 'Transparent'],
    colorAttribute: '',
    colors: {},
  },
  {
    type: 'background',
    attribute: 'background',
    values: ['Transparent', '#C8A1E0', '#F0A8D0', '#6482AD','#55AD9B', '#FFA62F', '#686D76', '#8CB9BD', '#A0937D'],
    gradients: [
      'linear-gradient(to right, #ff7e5f, #feb47b)',
      'linear-gradient(to right, #00c6ff, #0072ff)',
      'linear-gradient(to right, #fbb034, #ffdd00)',
      'linear-gradient(to right, #a2c2e5, #f9d29d)',
      'linear-gradient(to right, #e2e2e2, #2F3645)',
      'linear-gradient(to left, #020024, #097972, #00d4ff)',
      'linear-gradient(to left, #672193, #1f9cdd,  #ab00ff)',
      'linear-gradient(to left, #22c1c3, #fdbb2d)',
      'linear-gradient(to left, #fcb045, #fd1d1d, #833ab4)',
      'linear-gradient(to left, #94bbe9, #eeaeca)',
      'linear-gradient(to left, #d91e70,#94bbe9)',
    ],
    label: 'Background Color',
  },
  {
    label: 'Head',
    type: 'top',
    attribute: 'topType',
    values: [
      'NoHair',
      'Eyepatch',
      'Hat',
      'Hijab',
      'Turban',
      'WinterHat1',
      'WinterHat2',
      'WinterHat3',
      'WinterHat4',
      'LongHairBigHair',
      'LongHairBob',
      'LongHairBun',
      'LongHairCurly',
      'LongHairCurvy',
      'LongHairDreads',
      'LongHairFrida',
      'LongHairFro',
      'LongHairFroBand',
      'LongHairNotTooLong',
      'LongHairShavedSides',
      'LongHairMiaWallace',
      'LongHairStraight',
      'LongHairStraight2',
      'LongHairStraightStrand',
      'ShortHairDreads01',
      'ShortHairDreads02',
      'ShortHairFrizzle',
      'ShortHairShaggyMullet',
      'ShortHairShortCurly',
      'ShortHairShortFlat',
      'ShortHairShortRound',
      'ShortHairShortWaved',
      'ShortHairSides',
      'ShortHairTheCaesar',
      'ShortHairTheCaesarSidePart',
    ],
    colorAttribute: 'hairColor',
    colors: {
      Auburn: '#A55728',
      Black: '#2C1B18',
      Blonde: '#B58143',
      BlondeGolden: '#D6B370',
      Brown: '#724133',
      // BrownDark: '#4A312C',
      // PastelPink: '#F59797',
      // Platinum: '#ECDCBF',
      // Red: '#C93305',
      // SilverGray: '#E8E1E1',
    },
    hats: [
      'Hat',
      'Hijab',
      'Turban',
      'WinterHat1',
      'WinterHat2',
      'WinterHat3',
      'WinterHat4',
    ],
    hatColors: {
      Black: '#262E33',
      Blue01: '#65C9FF',
      Blue02: '#5199E4',
      Blue03: '#25557C',
      Gray01: '#E6E6E6',
      Gray02: '#929598',
      // Heather: "#3C4F5C",
      // PastelBlue: "#B1E2FF",
      // PastelGreen: "#A7FFC4",
      // PastelOrange: "#FFDEB5",
      // PastelRed: "#FFAFB9",
      // PastelYellow: "#FFFFB1",
      Pink: '#FF488E',
      Red: '#FF5C5C',
      // White: "#FFFFFF",
    },
  },
  {
    label: 'Facial Hair',
    type: 'facialHair',
    attribute: 'facialHairType',
    values: [
      'Blank',
      'BeardMedium',
      'BeardLight',
      'BeardMajestic',
      'MoustacheFancy',
      'MoustacheMagnum',
    ],
    colorAttribute: 'facialHairColor',
    colors: {
      Auburn: '#A55728',
      Black: '#2C1B18',
      Blonde: '#B58143',
      BlondeGolden: '#D6B370',
      // Brown: '#724133',
      BrownDark: '#4A312C',
      // Platinum: '#ECDCBF',
      // Red: '#C93305',
    },
  },
  {
    label: 'Eyes',
    type: 'eyes',
    attribute: 'eyeType',
    transform: 'scale(2) translate(14px,16px)',
    values: [
      'Close',
      'Cry',
      'Default',
      'Dizzy',
      'EyeRoll',
      'Happy',
      'Hearts',
      'Side',
      'Squint',
      'Surprised',
      'Wink',
      'WinkWacky',
    ],
  },
  {
    label: 'Eyebrows',
    type: 'eyebrows',
    attribute: 'eyebrowType',
    transform: 'scale(2) translate(13px,18px)',
    values: [
      'Angry',
      'AngryNatural',
      'Default',
      'DefaultNatural',
      'FlatNatural',
      'RaisedExcited',
      'RaisedExcitedNatural',
      'SadConcerned',
      'SadConcernedNatural',
      'UnibrowNatural',
      'UpDown',
      'UpDownNatural',
    ],
  },
  {
    label: 'Accessories',
    type: 'accessories',
    attribute: 'accessoriesType',
    values: [
      'Blank',
      'Kurt',
      'Prescription01',
      'Prescription02',
      'Round',
      'Sunglasses',
      'Wayfarers',
    ],
  },
  {
    label: 'Mouth',
    type: 'mouth',
    attribute: 'mouthType',
    transform: 'scale(2.5) translate(14px,12px)',
    values: [
      'Concerned',
      'Default',
      'Disbelief',
      'Eating',
      'Grimace',
      'Sad',
      'ScreamOpen',
      'Serious',
      'Smile',
      'Tongue',
      'Twinkle',
      'Vomit',
    ],
  },
  {
    label: 'Clothes',
    type: 'clothe',
    attribute: 'clotheType',
    values: [
      'BlazerShirt',
      'BlazerSweater',
      'CollarSweater',
      'GraphicShirt',
      'Hoodie',
      'Overall',
      'ShirtCrewNeck',
      'ShirtScoopNeck',
      'ShirtVNeck',
    ],
    colorAttribute: 'clotheColor',
    colors: {
      Black: '#262E33',
      Blue01: '#65C9FF',
      Blue02: '#5199E4',
      Blue03: '#25557C',
      Gray02: '#929598',
    },
    colors1: {
      // Gray01: '#E6E6E6',
      // Heather: '#3C4F5C',
      // PastelBlue: '#B1E2FF',
      PastelGreen: '#A7FFC4',
      PastelOrange: '#FFDEB5',
      PastelRed: '#FFAFB9',
      PastelYellow: '#FFFFB1',
      Pink: '#FF488E',
      Red: '#FF5C5C',
      // White: '#FFFFFF',
    },
  },
  // {
  //   label: 'Skin',
  //   type: 'skinColor',
  //   attribute: 'skinColor',
  //   values: [],
  //   colorAttribute: 'skinColor',
  //   colors: {
  //     Tanned: '#FD9841',
  //     // Yellow: '#F8D25C',
  //     // Pale: '#FFDBB4',
  //     Light: '#EDB98A',
  //     Brown: '#D08B5B',
  //     DarkBrown: '#AE5D29',
  //     Black: '#614335',
  //   },
  // },
];

export default Options;
