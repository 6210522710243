import styled, { css } from 'styled-components';

export const GithuFork = styled.a(
  () => css`
    width: 12.1em;
    height: 12.1em;
    position: absolute;
    overflow: hidden;
    top: 0;
    right: 0;
    z-index: 9999;
    pointer-events: none;
    font-size: 13px;
    text-decoration: none;
    text-indent: -999999px;
    @media (max-width: 550px) {
      font-size: 11px;
    }
    :before {
      content: '';
      padding: 0.38em 0;
      background-color: #423c34;
      background-image: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(rgba(0, 0, 0, 0)),
        to(rgba(0, 0, 0, 0.15))
      );
      background-image: -webkit-linear-gradient(
        top,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.15)
      );
      background-image: -moz-linear-gradient(
        top,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.15)
      );
      background-image: -ms-linear-gradient(
        top,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.15)
      );
      background-image: -o-linear-gradient(
        top,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.15)
      );
      background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.15)
      );
      -webkit-box-shadow: 0 0.15em 0.23em 0 rgba(0, 0, 0, 0.5);
      -moz-box-shadow: 0 0.15em 0.23em 0 rgba(0, 0, 0, 0.5);
      box-shadow: 0 0.15em 0.23em 0 rgba(0, 0, 0, 0.5);
      pointer-events: auto;
    }
    :after {
      content: attr(data-ribbon);
      color: #fff;
      font: 700 1em 'Helvetica Neue', Helvetica, Arial, sans-serif;
      line-height: 1.54em;
      text-decoration: none;
      text-shadow: 0 -0.08em rgba(0, 0, 0, 0.5);
      text-align: center;
      text-indent: 0;

      padding: 0.15em 0;
      margin: 0.15em 0;

      border-width: 0.08em 0;
      border-style: dotted;
      border-color: #fff;
      border-color: rgba(255, 255, 255, 0.7);
    }

    :before,
    :after {
      position: absolute;
      display: block;
      width: 15.38em;
      height: 1.54em;
      top: 3.23em;
      right: -3.23em;
      -webkit-box-sizing: content-box;
      -moz-box-sizing: content-box;
      box-sizing: content-box;
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  `
);

export const Container = styled.div(
  () => css`
    position: relative;
    border-radius: 8px;
    width: 400px;
    min-height: 500px;
    margin: auto;
    background: #f3f3f3;
    margin-top: 20px;
    padding: 20px 0;
    // box-shadow: 1px 1px 0px #f7f7f7, 2px 2px 0px #999, 3px 3px 0px #999,
    //   4px 4px 0px #d4d4d4, 5px 5px 0px #999, 6px 6px 0px #999;
  `
);

export const Pieces = styled.div(
  () => css`
    display: inline-block;
    position: relative;
    overflow: hidden;
    // border: 1px solid black;
    // padding: 5px;
    cursor: pointer;
    border-radius: 12px;
  `
);

export const PiecesDiv = styled.div(
  () => css`
    display: inline-block;
    position: relative;
    overflow: hidden;
    // border: 1px solid black;
    padding: 5px;
    background-color: #e8e8e8;
    cursor: pointer;
    border-radius: 4px;
  `
);

// export const Color = styled.div(
//   () => css`
//     display: inline-block;
//     height: 20px;
//     width: 20px;
//     cursor: pointer;

//     [color='#FFFFFF'] {
//       border: 1px solid #ccc;
//     }
//   `
// );

export const Color = styled.div`
  width: 60px;
  height: 60px;
  // border-radius: 50%;
  background-color: ${(props) => props.color};
  // display: inline-block;
  // margin: 5px;
  cursor: pointer;
  box-shadow: ${(props) =>
    props.color === 'Transparent'
      ? 'rgba(0, 0, 0, 0.35) 0px 5px 15px'
      : 'none'};
    border: ${(props) =>
      props.color === 'Transparent' ? '1px solid #000' : 'none'};
position: relative;
  
  &:before {
    content: ${(props) => (props.color === 'Transparent' ? '"None"' : '""')};
    color: #000;
    font-size: 12px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    pointer-events: none;
`;

export const Gradient = styled.div`
  width: 60px;
  height: 60px;
  // border-radius: 50%;
  background: ${(props) => props.gradient};
  // display: inline-block;
  // margin: 5px;
  cursor: pointer;
  border: none;
`;

export const ColorContain = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: ${(props) => props.gradient};
  display: inline-block;
  margin: 5px;
  cursor: pointer;
  border: none;
`;

export const None = styled.div(
  () => css`
    opacity: 0.2;
    font-size: 11px;
    position: absolute;
    top: 20px;
    left: 9px;
  `
);

export const StyledAvatar = styled.div(
  () => css`
    display: block;
    // width: 315px;
    height: 235px;
    margin-top: 28px;
  `
);

export const Tab = styled.div`
  font-size: 14px;
  text-align: center;
  border: 1px solid transparent;
  margin-right: 15px;
  cursor: pointer;
  color: #000000;

  ${({ selectedTab, type }) =>
    selectedTab === type &&
    css`
      color: #dc0272;
      font-weight: bold;
      text-decoration: underline;
      text-decoration-color: #dc0272;
      text-decoration-thickness: 5px;
      text-underline-offset: 5px;
    `};
`;

export const Tabpane = styled.div`
  box-sizing: border-box;
  display: none;
  width: 100%;
  gap: 10px;
  padding: 0 10px 10px;
  ${({ selectedTab, type }) =>
    selectedTab === type &&
    css`
      display: flex;
      align-items: center;
      justify-items: center;
      flex-wrap: wrap;
      align-content: center;
      justify-content: center;
    `}
`;

export const DownloadRow = styled.div(
  () => css`
    text-align: center;
  `
);

export const Tabs = styled.div(
  () => css`
    box-sizing: border-box;
    display: flex;
    position: relative;
    width: 100%;
    border-bottom: 2px solid #dcdcdc;
  `
);
export const ColorContainer = styled.div(
  () => css`
    display: flex;
    align-items: center;
    justify-items: center;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
  `
);

export const Tabpanes = styled.div(
  () => css`
    box-sizing: border-box;
    display: inline-block;
    width: 100%;
    margin-top: 2rem;
  `
);

export const Button = styled.button`
  border-radius: 4px;
  color: #fff;
  background-color: #75e339;
  border: 1px solid transparent;
  padding: 5px 7px;
  font-size: 16px;
  letter-spacing: 0.6px;
  width: 65px;
  font-weight: 600;
  margin: 0 5px;
  cursor: pointer;

  :active {
    box-shadow: 0 5px #75e339;
  }

  &:disabled {
    background-color: grey;
    cursor: not-allowed;
  }
`;
